import React, { Component } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import fermenter from 'assets/fermenter-min.png';

import ContactBar from 'components/ContactBar';

class Fermenter extends Component {
  render() {
    return (
      <div className="product-page">
        <Grid stackable>
          <Grid.Row className="banner">
            <Grid.Column computer={2} only={'computer'}></Grid.Column>
            <Grid.Column
              computer={5}
              className="photo-section"
              style={{ backgroundImage: `url(${fermenter})`, backgroundSize: '65%' }}
            ></Grid.Column>
            <Grid.Column computer={7} className="summary-section">
              <div className="full-height-container">
                <p className="title">Stainless Steel Fermenter</p>
                <p className="content">
                  An indigenous product of Lablinks that is designed to make it easier to operate
                  and maintain the traditional stainless steel fermenter. The mechanical seal, motor
                  and gearbox are all securely supported on the lid. The lid is operated by clamps
                  and can be engaged or disengaged with ease. The machine can be temperature
                  controlled and customized to seat on a table top or as a standalone.
                </p>
                <ul className="content">
                  <li>GMP standard with SS 316 construction</li>
                  <li>Helical blade impeller with speeds of range 0-150rpm</li>
                  <li>
                    Ports on the lid and vessel for inlet/outlet of air and media, temperature
                    sensor, pH sensor, dO2 sensor, pressure gauge and for sampling and harvesting of
                    the contents.
                  </li>
                  <li>Easily connect or drain steam and cooling water in the exterior jacket </li>
                </ul>
                <p className="sub-title">Capacity</p>
                <p className="content">10 Litres to 75 Litres</p>
                <p className="sub-title">Applications</p>
                <ul className="content">
                  <li>Fermentation of aerobic and anaerobic bacteria, yeasts, and fungi</li>
                  <li>Biofuel/Biopolymer production</li>
                  <li>Suitable for batch, fed-batch, and continuous/perfusion processes</li>
                </ul>
              </div>
              <Button
                as={Link}
                to="/requestquote"
                basic
                size="large"
                color="blue"
                className="request-quote-button"
              >
                Request a Quote
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ContactBar />
      </div>
    );
  }
}

export default Fermenter;
