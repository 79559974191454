import React, { Component } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import rollerbottle from 'assets/rollerbottle-min.png';

import ContactBar from 'components/ContactBar';

class RollerBottle extends Component {
  render() {
    return (
        <div className='product-page'>
          <Grid stackable>
            <Grid.Row className='banner' >
              <Grid.Column computer={2} only={'computer'}></Grid.Column>
              <Grid.Column computer={5} className='photo-section' style={{ backgroundImage: `url(${rollerbottle})` }}></Grid.Column>
              <Grid.Column computer={7} className='summary-section'>
                <div className="full-height-container">
                  <p className='title'>Roller Bottle Machine</p>
                  <p className='content'>
                    {'Indigenously developed rolling equipment with a robust build and capable of long years of continuous service.'
                    +'The machine can be customized for any size/number of bottles, temperature control and function.'}
                  </p>
                  <ul className='content'>
                    <li>Powder coated Aluminium and Stainless steel structure</li>
                    <li>Silicon rubberized rollers and closed ball bearings</li>
                    <li>Digital displays, controls and alarm functions</li>
                    <li>Servo-motor based speed control with speeds as low as 1 RPM without gearboxes</li>
                    <li>Customizable with an in-built incubator to maintain temperatures for optimum cell growth</li>
                  </ul>
                  <p className='sub-title'>Capacity</p>
                  <p className='content'>upto 100 bottles</p>
                  <p className='sub-title'>Applications</p>
                  <ul className='content'>
                    <li>Tissue culture of mammalian cells</li>
                    <li>Hybridomas and monoclonal antibody production</li>
                    <li>Both anchorage dependent and suspension cells</li>
                    <li>The surface area can also be increased several fold by proper use of microcarrier beads or macrocarrier matrices.</li>
                  </ul>
                </div>
                <Button as={Link} to='/requestquote' basic size='large' color='blue' className="request-quote-button">Request a Quote</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <ContactBar />
      </div>
    );
  }
}

export default RollerBottle;
