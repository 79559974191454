import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Menu, Sidebar } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import './theme.scss';

class SideNav extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <Sidebar
        as={Menu}
        animation="overlay"
        className="side-nav"
        visible={this.props.visible}
        icon="labeled"
        vertical
        inverted
      >
        <Menu.Item>
          <Menu.Menu>
            <Menu.Item as={Link} to="/">
              Home
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item>
          COMPANY
          <Menu.Menu>
            <Menu.Item as={Link} to="/about">
              About
            </Menu.Item>
            <Menu.Item>
              BIOPROCESSING
              <Menu.Menu>
                <Menu.Item as={Link} to="/torocell-bioreactor">
                  Torocell<sup>&reg;</sup> Disposable Bioreactor
                </Menu.Item>
                <Menu.Item as={Link} to="/torocell-bag">
                  Torocell<sup>&reg;</sup> Disposable Bag
                </Menu.Item>
                <Menu.Item as={Link} to="/roller-bottle">
                  Roller Bottle Machine
                </Menu.Item>
                <Menu.Item as={Link} to="/fermenter">
                  Stainless Steel Fermenter
                </Menu.Item>
                <Menu.Item as={Link} to="/laminar-flow">
                  Laminar Flow
                </Menu.Item>
                <Menu.Item as={Link} to="/incubator">
                  Incubator
                </Menu.Item>
              </Menu.Menu>
            </Menu.Item>
            <Menu.Item>
              SOLUTIONS
              <Menu.Menu>
                <Menu.Item as={Link} to="/solutions/torocell-for-agriculture">
                  Torocell<sup>&reg;</sup> for Agriculture
                </Menu.Item>
              </Menu.Menu>
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item>
          BIOPROCESSING
          <Menu.Menu>
            <Menu.Item as={Link} to="/torocell-bioreactor">
              Torocell<sup>&reg;</sup> Disposable Bioreactor
            </Menu.Item>
            <Menu.Item as={Link} to="/torocell-bag">
              Torocell<sup>&reg;</sup> Disposable Bag
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
      </Sidebar>
    );
  }
}

export default SideNav;
