import React, { Component } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import laminarflow from 'assets/laminarflow-min.png';

import ContactBar from 'components/ContactBar';

class LaminarFlow extends Component {
  render() {
    return (
        <div className='product-page'>
          <Grid stackable>
            <Grid.Row className='banner' >
              <Grid.Column computer={2} only={'computer'}></Grid.Column>
              <Grid.Column computer={5} className='photo-section' style={{ backgroundImage: `url(${laminarflow})` }}></Grid.Column>
              <Grid.Column computer={7} className='summary-section'>
                <div className="full-height-container">
                  <p className='title'>Laminar Flow</p>
                  <p className='content'>
                    {'Our laminar flow is robustly built for any application in the lab. It was developed for use at our own facilities '
                    +'and offers a whole range of features and flexibility. It can be customized for specific requirements as well.'}
                  </p>
                  <ul className='content'>
                    <li>Powder coated CRCA body and SS304 worktable</li>
                    <li>High power, noise and vibration free blowers</li>
                    <li>99.99% down to 0.3 micron HEPA filters: DOP tested and certified</li>
                    <li>Germicidal UV tube lamps and protective clear glass front panel</li>
                    <li>Castor wheel supported for mobility andd height adjustable</li>
                  </ul>
                  <p className='sub-title'>Capacity</p>
                  <p className='content'>1200mm x 600mm x 600mm</p>
                  <p className='sub-title'>Applications</p>
                  <ul className='content'>
                    <li>Suitable for general microbial work</li>
                  </ul>
                </div>
                <Button as={Link} to='/requestquote' basic size='large' color='blue' className="request-quote-button">Request a Quote</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <ContactBar />
      </div>
    );
  }
}

export default LaminarFlow;
