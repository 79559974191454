import React, { Component } from 'react';
import { Button, Embed, Grid, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import torocell from 'assets/rectbluemin.png';
import features from 'assets/descrip.png';
import lllogo from 'assets/lllogotrans.png';

import ContactBar from 'components/ContactBar';

class TorocellBioreactor extends Component {
  render() {
    return (
        <div className='product-page'>
          <Grid stackable>
            <Grid.Row className='banner' >
              <Grid.Column computer={2} only={'computer'}></Grid.Column>
              <Grid.Column computer={5} className='photo-section' style={{ backgroundImage: `url(${torocell})` }}></Grid.Column>
              <Grid.Column computer={7} className='summary-section'>
                <div className="full-height-container">
                  <p className='title'>Torocell<sup>&reg;</sup> Disposable Bioreactor</p>
                  <p className='content'>
                    A revolution in single-use bioreactors, Torocell<sup>&reg;</sup> offers powerful mixing, high-oxygen transfer and phenomenal efficiency
                    in fermentation. It overcomes the issues of single-use systems with its unique gyrational motion of fluid. Its versatility
                     makes it an excellent choice for bacterial, fungal, avian, mammalian suspension cell cultures.
                  </p>
                  <ul className='content'>
                    <li>GMP standard</li>
                    <li>Design enables very slow to rapid mixing of fluids</li>
                    <li>Fully automated programmable control</li>
                    <li>Choose between PV-EVOH Disposable Bags for suspension cultures or
                        auto-clavable glass and stainless steel vessels with anchorage matrix for anchorage based cultures
                    </li>
                    <li>Fully automated programmable control</li>
                  </ul>
                  <p className='sub-title'>Capacity</p>
                  <p className='content'>2 Litres to 30 Litres</p>
                  <p className='sub-title'>Applications</p>
                  <ul className='content'>
                    <li>Fermentation of aerobic and anaerobic bacteria, yeasts, and fungi</li>
                    <li>Cell culture of mammalian, insect, and human cell lines</li>
                    <li>Stem cell culture, vaccine, monoclonal antibody or biofuel/biopolymer production</li>
                    <li>Suitable for batch, fed-batch, and continuous/perfusion processes</li>
                  </ul>
                </div>
                <Button as={Link} to='/requestquote' basic size='large' color='blue' className="request-quote-button">Request a Quote</Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="presentation">
              <Grid.Column computer={4} only={'computer'}></Grid.Column>
              <Grid.Column computer={8}>
                <p>Watch Presentation</p>
                <Embed
                  aspectRatio='16:9'
                  id='ERtJvtAXFvY'
                  source='youtube'
                  placeholder={lllogo}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered className="features">
              <Grid.Column computer={8}>
                <Image src={features} fluid />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <ContactBar />
      </div>
    );
  }
}

export default TorocellBioreactor;
