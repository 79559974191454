import React from 'react';
import {
  Route,
  BrowserRouter as Router,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from 'react-router-dom';

import PageWrapper from 'components/PageWrapper';
import withTracker from 'components/withTracker';
import Home from 'pages/Home';
import About from 'pages/About';
import Contact from 'pages/Contact';
import RequestQuote from 'pages/RequestQuote';
import PageNotFound from 'pages/PageNotFound';

import BioProcessEquipment from 'pages/BioProcessEquipment';
import TorocellBioreactor from 'pages/BioProcessEquipment/TorocellBioreactor';
import TorocellBag from 'pages/BioProcessEquipment/TorocellBag';
import RollerBottle from 'pages/BioProcessEquipment/RollerBottle';
import Fermenter from 'pages/BioProcessEquipment/Fermenter';
import LaminarFlow from 'pages/BioProcessEquipment/LaminarFlow';
import Incubator from 'pages/BioProcessEquipment/Incubator';

import Solutions from 'pages/Solutions';
import TorocellForAgriculture from 'pages/Solutions/TorocellForAgriculture';
import registerServiceWorker from './registerServiceWorker';
import 'semantic-ui-css/semantic.min.css';
import 'slick-carousel/slick/slick.css';

import { createRoot } from 'react-dom/client';

import './index.scss';

// const App = () => (
//   <Router>
//     <PageWrapper>
//       <Routes>
//         <Route exact path="/" component={withTracker(Home)} />
//         <Route exact path="/about" component={withTracker(About)} />
//         <Route exact path="/contact" component={withTracker(Contact)} />
//         <Route exact path="/requestquote" component={withTracker(RequestQuote)} />
//         <Route exact path="/bio-process-equipment" component={withTracker(BioProcessEquipment)} />
//         <Route exact path="/torocell-bioreactor" component={withTracker(TorocellBioreactor)} />
//         <Route exact path="/torocell-bag" component={withTracker(TorocellBag)} />
//         <Route exact path="/roller-bottle" component={withTracker(RollerBottle)} />
//         <Route exact path="/fermenter" component={withTracker(Fermenter)} />
//         <Route exact path="/laminar-flow" component={withTracker(LaminarFlow)} />
//         <Route exact path="/incubator" component={withTracker(Incubator)} />
//         <Route exact path="/solutions" component={withTracker(Solutions)} />
//         <Route
//           exact
//           path="/solutions/torocell-for-agriculture"
//           component={withTracker(TorocellForAgriculture)}
//         />
//         <Route component={withTracker(PageNotFound)} />
//       </Routes>
//     </PageWrapper>
//   </Router>
// );

const router = createBrowserRouter([
  {
    element: <PageWrapper />,
    children: [
      { path: '/', Component: Home },
      { path: '/about', Component: About },
      { path: '/contact', Component: Contact },
      { path: '/requestquote', Component: RequestQuote },
      { path: '/bio-process-equipment', Component: BioProcessEquipment },
      { path: '/torocell-bioreactor', Component: TorocellBioreactor },
      { path: '/torocell-bag', Component: TorocellBag },
      { path: '/roller-bottle', Component: RollerBottle },
      { path: '/fermenter', Component: Fermenter },
      { path: '/laminar-flow', Component: LaminarFlow },
      { path: '/incubator', Component: Incubator },
      { path: '/solutions', Component: Solutions },
      { path: '/solutions/torocell-for-agriculture', Component: TorocellForAgriculture },
      { path: '*', Component: PageNotFound },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

registerServiceWorker();
