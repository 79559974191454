import React, { Component } from "react";
import { Grid, Form, Icon } from "semantic-ui-react";

import "./theme.scss";

class RequestQuote extends Component {
  render() {
    return (
      <div className="request-quote-page">
        <Grid>
          <Grid.Row centered className="request-form">
            <Grid.Column computer={8} mobile={16}>
              <p className="header"> Request Quote</p>
              <div className="content">
                <Form
                  action="https://formspree.io/akgandlur@lablinksbiotech.com"
                  method="POST"
                >
                  <Form.Input fluid label="Company" name="company" />
                  <Form.Group widths="equal">
                    <Form.Input fluid label="First Name" name="firstName" />
                    <Form.Input fluid label="Last Name" name="lastName" />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input fluid label="Phone Number" name="phoneNumber" />
                    <Form.Input fluid label="Email" name="email" type="email" />
                  </Form.Group>
                  <Form.TextArea
                    label="Address"
                    placeholder="Street Name, City, Postal Code, Country"
                    name="message"
                    rows={3}
                  />
                  <Form.TextArea
                    label="My Request"
                    placeholder="Which product are you interested in?"
                    name="request"
                    rows={6}
                  />
                  <input
                    type="hidden"
                    name="_next"
                    value="//lablinksbiotech.com/contact"
                  />
                  <Form.Button
                    type="submit"
                    value="send"
                    floated="right"
                    color="teal"
                  >
                    <Icon name="send" />
                    Send
                  </Form.Button>
                </Form>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default RequestQuote;
