import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';

import './theme.scss';

class About extends Component {
  render() {
    return (
      <div className="about-page">
        <Grid>
          <Grid.Row centered className="banner">
            <Grid.Column computer={8} mobile={16}>
              <p className="header">
                We build simple and powerful cell-culture machines for the biotechnology industry
              </p>
              <p className="content">
                For over 30 years, Lablinks Biotech has been inventing products that make research
                development teams radically productive. Our plethora of products ranging from
                stainless steel bioreactors, roller bottle machines, filtration systems to award
                winning disposable bioreactors are extensively used by research institutes and
                companies worldwide.
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered className="team">
            <Grid.Column computer={8} mobile={16}></Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default About;
