import { Component } from 'react';
import { Grid, Image, Divider, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import rectblue from 'assets/rectbluemin.png';
import hand from 'assets/hand.jpg';
import plant from 'assets/plant-min.jpeg';

import syngene from 'assets/syngenemin.jpeg';
import iitm from 'assets/iitm1min.jpg';
import cftri from 'assets/cftrimin.png';
import mediclone from 'assets/mediclonemin.jpg';

import AgroBanner from 'components/AgroBanner';
import ResearchBanner from 'components/ResearchBanner';
import ContactBar from 'components/ContactBar';

import './theme.scss';
import PageWrapper from 'components/PageWrapper';

class Home extends Component {
  getBanners = () => {
    return [<AgroBanner />, <ResearchBanner />];
  };

  state = {
    loopCounter: 0,
    banners: this.getBanners(),
  };

  categorySection = () => {
    return (
      <Grid.Row centered className="product-categories">
        <Grid.Column computer={4} mobile={13} className="category-card">
          <p className="heading">BioProcess Equipment</p>
          <div className="image" style={{ backgroundImage: `url(${rectblue})` }}></div>
          <div className="description-container">
            <p className="description">
              Discover our range of cell-culture systems which include disposable bioreactors,
              roller bottle machines, fermenters and more...
            </p>
          </div>
          <Button basic color="blue" fluid as={Link} to="/bio-process-equipment">
            Explore
          </Button>
        </Grid.Column>
        <Grid.Column computer={4} mobile={13} className="category-card">
          <p className="heading">Solutions</p>
          <div className="image" style={{ backgroundImage: `url(${hand})` }}></div>
          <div className="description-container">
            <p className="description">
              Innovative solutions that have been derived from relentless research at Lablinks. Know
              more about how we can help.
            </p>
          </div>
          <Button basic color="blue" fluid as={Link} to="/solutions">
            Explore
          </Button>
        </Grid.Column>
        <Grid.Column computer={4} mobile={13} className="category-card">
          <p className="heading">Bio-Products</p>
          <div
            className="image"
            style={{
              backgroundImage: `url(${plant})`,
              backgroundSize: '90%',
              backgroundPosition: 'bottom',
            }}
          ></div>
          <div className="description-container">
            <p className="description">
              Biofertilizers, Biopesticides, Aquaculture and Probiotics. Our range of manufacturing
              prowess in biological products.
            </p>
          </div>
          <Button
            basic
            color="blue"
            fluid
            as={Link}
            to="http://www.torusbiosciences.com"
            target="_blank"
          >
            Explore
          </Button>
        </Grid.Column>
      </Grid.Row>
    );
  };

  clientSection = () => {
    return (
      <Grid.Row centered className="client-section">
        <Grid.Column computer={12} mobile={16}>
          <Divider hidden />
          <p className="header">Our Clients</p>
          <p className="content">
            {"Over the last 27 years we've innovated for several companies and research groups."}
          </p>
          <div className="client-container">
            <div className="clients">
              <Image className="client-logo" bordered src={syngene} size="small" />
              <Image className="client-logo" bordered src={iitm} size="small" />
              <Image className="client-logo" src={cftri} size="small" />
              <Image className="client-logo" src={mediclone} size="small" />
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    );
  };

  render() {
    var settings = {
      dots: false,
      speed: 1000,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 6000,
      arrows: false,
    };
    return (
      <div className="home">
        <div style={{ margin: '0 -14px' }}>
          <Slider {...settings}>
            {this.state.banners.map((banner, i) => (
              <div key={i}>{banner}</div>
            ))}
          </Slider>
        </div>
        <Grid>
          <Grid.Row centered className="about">
            <Grid.Column computer={10} mobile={14}>
              <p>
                Lablinks Biotech is reinventing cell-culture for biotechnology companies worldwide
              </p>
            </Grid.Column>
          </Grid.Row>
          {this.categorySection()}
          {this.clientSection()}
        </Grid>
        <ContactBar />
      </div>
    );
  }
}

export default Home;
