import React, { Component } from 'react';
import { Grid, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import './theme.scss';

class PageNotFound extends Component {
  render() {
    return (
      <div className="page-not-found">
        <Grid>
          <Grid.Row centered className="banner">
            <Grid.Column computer={8} mobile={16}>
              <p className="header">We couldn't find the page you're looking for</p>
              <p className="content">But here are a few interesting links to explore</p>
              <ul className="links">
                <li>
                  <Label as={Link} to="/">
                    Home
                  </Label>
                </li>
                <li>
                  <Label as={Link} to="/bio-process-equipment">
                    BioProcess Eqipment
                  </Label>
                </li>
                <li>
                  <Label as={Link} to="/bio-process-equipment/torocell-bioreactor">
                    Torocell Disposable Bioreactor
                  </Label>
                </li>
                <li>
                  <Label as={Link} to="/blog">
                    Blog
                  </Label>
                </li>
                <li>
                  <Label as={Link} to="/contact">
                    Contact
                  </Label>
                </li>
              </ul>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default PageNotFound;
