import React, { Component } from 'react';
import { Grid, Card } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import agriculture from 'assets/wheat-min.jpeg';

import './theme.scss';

class Solutions extends Component {
  render() {
    return (
      <div className="solutions">
        <Grid>
          <Grid.Row centered className="products">
            <Grid.Column computer={12} mobile={16}>
              <div>
                <p className="heading">Solutions</p>
              </div>
              <Card.Group itemsPerRow={3} stackable>
                <Card className="product-card" as={Link} to="/solutions/torocell-for-agriculture">
                  <div
                    className="product-image"
                    style={{ backgroundImage: `url(${agriculture})` }}
                  ></div>
                  <Card.Content>
                    <Card.Header>
                      Torocell<sup>&reg;</sup> For Agriculture
                    </Card.Header>
                    <Card.Description>
                      Innovation that produces biofertilisers and biopesticides with high-yield and
                      low-capital investment
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Card.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default Solutions;
