import React, { Component } from 'react';
import { Grid, Embed, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import torobag from 'assets/torobagmin.png';
import lllogo from 'assets/lllogotrans.png';

import ContactBar from 'components/ContactBar';

class TorocellBag extends Component {
  render() {
    return (
      <div className='product-page'>
        <Grid stackable>
          <Grid.Row className='banner' >
            <Grid.Column computer={2} only={'computer'}></Grid.Column>
            <Grid.Column computer={5} mobile={14} className='photo-section'
              style={{
                backgroundImage: `url(${torobag})`,
                backgroundSize: '60%'
              }}>
            </Grid.Column>
            <Grid.Column computer={7} mobile={16} className='summary-section'>
              <div className="full-height-container">
                <p className='title'>Torocell<sup>&reg;</sup> Disposable Bag</p>
                <p className='content'>
                  Fluid management made easy with the toroid shaped Torocell<sup>&reg;</sup> Disposable Bag. Its specially designed to ensure
                  a unidrectional flow of fluid while creating eddies for powerful mixing. The material is carefully chosen to not interact with
                  the cells and customizable ports are provided to make handling, testing and sampling easy while avoiding contamination.
                </p>
                <ul className='content'>
                  <li>PV-EVOH or Medical Grade PVC Material Bags</li>
                  <li>Pre-sterilized by Gamma Irradiation</li>
                  <li>Silicon tubing manifold assembly provided for easy connections</li>
                  <li>Customizable Ports and Designs</li>
                </ul>
                <p className='sub-title'>Capacity</p>
                <p className='content'>2 Litres to 30 Litres</p>
                <p className='sub-title'>Applications</p>
                <ul className='content'>
                  <li>Fermentation of aerobic and anaerobic bacteria, yeasts, and fungi</li>
                  <li>Cell culture of mammalian, insect, and human cell lines</li>
                  <li>Stem cell culture, vaccine, monoclonal antibody or biofuel/biopolymer production</li>
                  <li>Suitable for batch, fed-batch, and continuous/perfusion processes</li>
                </ul>
              </div>
              <Button as={Link} to='/requestquote' basic size='large' color='blue' className='request-quote-button'>Request a Quote</Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="presentation">
            <Grid.Column computer={4} only={'computer'}></Grid.Column>
            <Grid.Column computer={8}>
              <p>Watch Presentation</p>
              <Embed
                aspectRatio='16:9'
                id='ERtJvtAXFvY'
                source='youtube'
                placeholder={lllogo}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ContactBar />
      </div>
    );
  }
}

export default TorocellBag;
