import React, { Component } from "react";
import { Button, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";

import "./theme.scss";

class ContactBar extends Component {
  render() {
    return (
      <div className="contact-bar">
        <Grid>
          <Grid.Row centered className="container">
            <Grid.Column className="content" computer={12} mobile={15}>
              <span className="message">Get in touch with us</span>
              <Button as={Link} to="/contact" color="blue">
                Contact Us
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default ContactBar;
