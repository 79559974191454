import React, { Component } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import agrobanner from 'assets/agrobannerreallymin.jpg';

import './theme.scss';

class AgroBanner extends Component {
  render() {
    return (
      <div className="agro-banner">
        <Grid>
          <Grid.Row centered style={{ backgroundImage: `url(${agrobanner})` }}>
            <Grid.Column largeScreen={12} computer={12} mobile={16}>
              <div className="banner-container">
                <p className="heading">
                  Agriculture of the <br />
                  future
                </p>
                <p className="content">
                  Our single-use systems provide farmers with upto 10000 litres of biofertilizers
                  and biopesticides in 3 days.
                </p>
                <Button
                  basic
                  as={Link}
                  to="/solutions/torocell-for-agriculture"
                  className="action-link"
                  inverted
                >
                  Read More
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default AgroBanner;
