import React, { Component } from "react";
import { Grid, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

import researchbanner from "assets/microscopereallymin.jpeg";

import "./theme.scss";

class ResearchBanner extends Component {
  render() {
    return (
      <div className="research-banner">
        <Grid>
          <Grid.Row
            centered
            style={{ backgroundImage: `url(${researchbanner})` }}
          >
            <Grid.Column largeScreen={12} computer={12} mobile={16}>
              <div className="banner-container">
                <p className="heading">Products optimized for research</p>
                <p className="content">
                  Cell-culture systems with enhanced productivity for research
                  teams and industries.
                </p>
                <Button
                  basic
                  as={Link}
                  to="/bio-process-equipment"
                  className="action-link"
                  inverted
                >
                  Explore
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default ResearchBanner;
