import React, { Component } from 'react';
import { Grid, Icon, Label } from 'semantic-ui-react';

import wheatbanner from 'assets/wheat-min.jpeg';
import torocell from 'assets/rectbluemin.png';
import torobag from 'assets/torobagmin.png';

import ContactBar from 'components/ContactBar';

import './theme.scss';

class TorocellForAgriculture extends Component {
  render() {
    return (
      <div className="torocell-for-agriculture-page">
        <Grid>
          <Grid.Row centered className="title" style={{ backgroundImage: `url(${wheatbanner})` }}>
            <Grid.Column computer={12} mobile={15}>
              <p className="header">
                Torocell<sup>&reg;</sup> for Agriculture
              </p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered className="introduction">
            <Grid.Column computer={12} mobile={16}>
              <div className="content">
                <p className="header">Produce biofertilizers and biopesticides with ease</p>
                <p className="sub-header">
                  Torocell<sup>&reg;</sup> Disposable Bioreactors are the perfect cell-culture
                  machines to produce bioeffectors at a high scale with low capital investments.
                </p>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered className="about-torocell">
            <Grid.Column
              computer={6}
              mobile={16}
              className="showcase"
              style={{
                backgroundImage: `url(${torocell})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            ></Grid.Column>
            <Grid.Column computer={6} mobile={16}>
              <div className="content">
                <p className="header">Unique Gyrational Flow</p>
                <p className="sub-header">
                  The patented toroidal flow ensures excellent mixing and uniform motion without any
                  shear. This gives impeccable control over the speed and aeration of the mixture.
                </p>
                {/* <a
                  href="https://www.youtube.com/watch?v=ERtJvtAXFvY"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon name="video play" />
                  Watch Video
                </a> */}
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered className="productivity" reversed="computer">
            <Grid.Column computer={6} mobile={16} className="showcase">
              <div className="content">
                <p className="highlight">
                  <span className="statistic">
                    30 x 10<sup>9</sup>
                  </span>
                  CFU / ml<sup>*</sup>
                </p>
              </div>
              <span className="fine-print">
                <sup>*</sup> study conducted on <i>Azospirillum Brasilense</i>
              </span>
            </Grid.Column>
            <Grid.Column computer={6} mobile={16}>
              <div className="content">
                <p className="header">Superior Bacterial Cell Counts</p>
                <p className="sub-header">
                  The unique flow gives extremely high productivity in producing bioeffectors with a
                  small footprint. It is capable of manufacturing over biofertilizers that can
                  sustain 10,000 acres of farmland every month.
                </p>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered className="about-torocell">
            <Grid.Column
              computer={6}
              mobile={16}
              className="showcase"
              style={{
                backgroundImage: `url(${torobag})`,
                backgroundSize: '70%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            ></Grid.Column>
            <Grid.Column computer={6} mobile={16}>
              <div className="content">
                <p className="header">Contamination-Free Culture Bags</p>
                <p className="sub-header">
                  The gamma pre-sterilized culture bags ensure zero downtime for cleaning or
                  maintenance. Lablinks also provides bags pre-filled with media in powder form
                  needing very little training to use.
                </p>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered className="products">
            <Grid.Column computer={3} mobile={16} className="showcase">
              <div className="content">
                <p className="header">APPLICATIONS</p>
              </div>
            </Grid.Column>
            <Grid.Column computer={9} mobile={16} className="showcase content-with-list">
              <div className="content">
                <p>
                  Lablinks has researched and developed protocols available for the following
                  Bacteria and Fungus:
                </p>
                <ul className="items">
                  <li>
                    <Label>Azospirillum Brasilense</Label>
                  </li>
                  <li>
                    <Label>Azotobacter chroococcum</Label>
                  </li>
                  <li>
                    <Label>Trichoderma viride</Label>
                  </li>
                  <li>
                    <Label>Pseudomonas fluorescens</Label>
                  </li>
                  <li>
                    <Label>Pseudomonas putida</Label>
                  </li>
                  <li>
                    <Label>Bacillus megaterium</Label>
                  </li>
                  <li>
                    <Label>Frateuria aurantia</Label>
                  </li>
                  <li>
                    <Label>Rhizobium</Label>
                  </li>
                </ul>
              </div>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row centered className="operations">
            <Grid.Column computer={12} mobile={16}>
              <div className="content">
                <p className="header">Distributed Production Plants</p>
                <p className="sub-header">
                  Biofertilisers and Biopesticides contain live bacteria and transporting them from
                  a central manufacturing facility to the point of use is a hassle. The cell-count
                  can drop several fold despite additives and carriers.
                </p>
                <p className="sub-header">
                  Torocell<sup>&reg;</sup> solves this problem by reducing the capital investment to
                  create small distributed production plants at the point of consumption. It has a
                  very high yield, preset protocols and the Torocell<sup>&reg;</sup> bags with
                  pre-filled media make it easy even for unskilled labour to manufacture
                  bioeffectors.
                </p>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <ContactBar />
      </div>
    );
  }
}

export default TorocellForAgriculture;
