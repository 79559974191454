import React, { Component } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import incubator from 'assets/incubators-min.png';

import ContactBar from 'components/ContactBar';

class Incubator extends Component {
  render() {
    return (
        <div className='product-page'>
          <Grid stackable>
            <Grid.Row className='banner' >
              <Grid.Column computer={2} only={'computer'}></Grid.Column>
              <Grid.Column computer={5} className='photo-section' style={{ backgroundImage: `url(${incubator})`, backgroundSize: '70%' }}></Grid.Column>
              <Grid.Column computer={7} className='summary-section'>
                <div className="full-height-container">
                  <p className='title'>Incubator</p>
                  <p className='content'>
                    {'Lablinks manufactures incubators and ovens for biotechnology laboratories with exceptional temperature maintenance for '+
                    'bioprocesses. These are constructed using stainless steel, anodised aluminium and glass and are suitable for GMP operations. '+
                    'Standard digital temperature controllers or microprocessor based controls with Pt 100 sensors along with forced air '+
                    'circulation or natural convection are used for precise temperature control.'}
                  </p>
                  <p className='sub-title'>Applications</p>
                  <ul className='content'>
                    <li>General purpose laboratory use</li>
                    <li>For cell cultures</li>
                  </ul>
                </div>
                <Button as={Link} to='/requestquote' basic size='large' color='blue' className="request-quote-button">Request a Quote</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <ContactBar />
      </div>
    );
  }
}

export default Incubator;
