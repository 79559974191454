import React, { Component } from 'react';
import { Grid, Icon, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import lllogo from 'assets/lllogotrans.png';
import './theme.scss';

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <Grid>
          <Grid.Row className="links-container" centered>
            <Grid.Column computer={2}></Grid.Column>
            <Grid.Column computer={4} mobile={16}>
              <Image src={lllogo} />
            </Grid.Column>
            <Grid.Column computer={2} mobile={16}>
              <p className="header">COMPANY</p>
              <Link to="/about">About</Link>
              <Link to="/contact">Contact Us</Link>
            </Grid.Column>
            <Grid.Column computer={3} mobile={16}>
              <p className="header">BIOPROCESSING</p>
              <Link to="/torocell-bioreactor">
                Torocell<sup>&reg;</sup> Disposable Bioreactor
              </Link>
              <Link to="/torocell-bag">
                Torocell<sup>&reg;</sup> Disposable Bag
              </Link>
              <Link to="/roller-bottle">Roller Bottle Machine</Link>
              <Link to="/fermenter">Stainless Steel Fermenter</Link>
              <Link to="/laminar-flow">Laminar Flow</Link>
              <Link to="/incubator">Incubator</Link>
            </Grid.Column>
            <Grid.Column computer={3} mobile={16}>
              <p className="header">SOLUTIONS</p>
              <Link to="/solutions/torocell-for-agriculture">
                Torocell<sup>&reg;</sup> for Agriculture
              </Link>
            </Grid.Column>
            <Grid.Column computer={2}></Grid.Column>
          </Grid.Row>
          <Grid.Row className="copyright-container" centered>
            <Grid.Column computer={2}></Grid.Column>
            <Grid.Column computer={4} mobile={16}>
              <div className="content">&copy; 2017 Lablinks Biotech Pvt Ltd</div>
            </Grid.Column>
            <Grid.Column computer={6}></Grid.Column>
            <Grid.Column computer={4} mobile={16}>
              <div className="content">
                <span className="social-links">
                  Follow: &nbsp;
                  <Link to="https://twitter.com/lablinksbiotech" target="_blank">
                    <Icon size={'large'} name="twitter" />
                  </Link>
                  <Link to="https://www.linkedin.com/company/2473457/" target="_blank">
                    <Icon size={'large'} name="linkedin square" />
                  </Link>
                </span>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default Footer;

//         <div style={{ height: '400px', width: '15%'}}>
//             {/*
//             <p style={{ fontSize: '16px', fontWeight: 400, color: '#323648', marginBottom: '25px' }}> BIOEFFECTORS </p>
//             <p style={{ fontSize: '14px', fontWeight: 200, color: '#323648' }}> Bio-Fertilizers </p>
//             <p style={{ fontSize: '14px', fontWeight: 200, color: '#323648' }}> Bio-Pesticides </p>
//             <p style={{ fontSize: '14px', fontWeight: 200, color: '#323648' }}> Aquaculture Mixtures </p>
//             <p style={{ fontSize: '14px', fontWeight: 200, color: '#323648' }}> Bio-Toilet Mixtures </p>
//             */}
//         </div>
//             <p style={{ fontSize: '14px', fontWeight: 200, color: '#323648' }}> Custom Solutions </p>
//             <p style={{ fontSize: '14px', fontWeight: 200, color: '#323648' }}> Training </p>
//             <p style={{ fontSize: '14px', fontWeight: 200, color: '#323648' }}> R&D </p>
//             <p style={{ fontSize: '14px', fontWeight: 200, color: '#323648' }}> Contract Manufacturing </p>
//             <p style={{ fontSize: '14px', fontWeight: 200, color: '#323648' }}> Careers </p>*/}
//             <p style={{ fontSize: '14px', fontWeight: 200, color: '#323648' }}> Magnetic Mixer </p>*/}
