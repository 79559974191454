import React, { Component } from 'react';
import { Button, Grid, Icon, Form } from 'semantic-ui-react';

import './theme.scss';

class Contact extends Component {
  render() {
    return (
      <div className="contact-page">
        <Grid>
          <Grid.Row centered className="email-us">
            <Grid.Column computer={12}>
              <div>
                <p>{`We'd love to hear from you`}</p>
                <Button basic color="teal" href="tel:+919972873740" size="medium">
                  <Icon name="phone" />
                  Call
                </Button>
                <Button
                  basic
                  color="teal"
                  href="mailto:akgandlur@lablinksbiotech.com?subject=Information about Lablinks Biotech"
                  target="_blank"
                  size="medium"
                >
                  <Icon name="mail" />
                  Email
                </Button>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered className="information">
            <Grid.Column computer={4} mobile={16} className="section">
              <div>
                <p className="heading">Address</p>
                <p className="content">
                  C-16, KSSIDC Industrial Estate,
                  <br /> Hoskote - 562114
                  <br />
                  Bangalore, Karnataka, India.
                </p>
              </div>
            </Grid.Column>
            <Grid.Column computer={3} mobile={16} className="section">
              <div>
                <p className="heading">Phone</p>
                <p className="content">+91-9343748099</p>
                <p className="content">+91-9945308097</p>
                <p className="content">+91-9972873740</p>
              </div>
            </Grid.Column>
            <Grid.Column computer={3} mobile={16} className="section">
              <div>
                <p className="heading">Email</p>
                <p className="content">ravindranath@lablinksbiotech.com</p>
                <p className="content">akgandlur@lablinksbiotech.com</p>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered className="send-message">
            <Grid.Column computer={6} mobile={16}>
              <p>Send a Message</p>
              <Form action="https://formspree.io/akgandlur@lablinksbiotech.com" method="POST">
                <Form.Group widths="equal">
                  <Form.Input fluid label="First Name" name="firstName" />
                  <Form.Input fluid label="Last Name" name="lastName" />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input fluid label="Phone Number" name="phoneNumber" />
                  <Form.Input fluid label="Email" name="email" type="email" />
                </Form.Group>
                <Form.TextArea
                  label="Message"
                  placeholder="How can we help?"
                  name="message"
                  rows={5}
                />
                <input type="hidden" name="_next" value="//lablinksbiotech.com/contact" />
                <Form.Button type="submit" value="send" floated="right" color="teal">
                  <Icon name="send" />
                  Send
                </Form.Button>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default Contact;
