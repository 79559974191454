import React, { Component } from 'react';
import { Dropdown, Grid, Image, Menu, Icon, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import logowritten from 'assets/logowritten.png';
import './theme.scss';

class Navbar extends Component {
  render() {
    return (
      <div className="navbar">
        <Grid>
          <Grid.Row centered className="navbar-computer" only={'computer'}>
            <Grid.Column computer={12} mobile={16}>
              <Menu borderless stackable>
                <Menu.Item as={Link} to={`/`}>
                  <Image src={logowritten} height={35} />
                </Menu.Item>

                <Menu.Menu position="right" className="nav-links">
                  <Dropdown item text="BIOPROCESSING">
                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to="/torocell-bioreactor">
                        Torocell<sup>&reg;</sup> Disposable Bioreactor
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/torocell-bag">
                        Torocell<sup>&reg;</sup> Disposable Bag
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/roller-bottle">
                        Roller Bottle Machine
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/fermenter">
                        Stainless Steel Fermenter
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/laminar-flow">
                        Laminar Flow
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/incubator">
                        Incubator
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown item text="SOLUTIONS">
                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to="/solutions/torocell-for-agriculture">
                        Torocell<sup>&reg;</sup> for Agriculture
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown item text="COMPANY">
                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to="/about">
                        About
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Menu.Item as={Link} to="/contact">
                    CONTACT US
                  </Menu.Item>
                </Menu.Menu>
              </Menu>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered className="navbar-mobile" only={'mobile'}>
            <Grid.Column mobile={16}>
              <Menu borderless>
                <Menu.Item as={Link} to={`/`}>
                  <Image src={logowritten} height={35} />
                </Menu.Item>
                <Menu.Item position="right">
                  <Button className="sidebar-toggle" onClick={this.props.toggleSidebar} icon>
                    <Icon name="bars" />
                  </Button>
                </Menu.Item>
              </Menu>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default Navbar;

/*
<Dropdown.Item as={Link} to='/roller-bottle'>Roller Bottle Machine</Dropdown.Item>
<Dropdown.Item as={Link} to='/laminar-flow'>Laminar Flow</Dropdown.Item>
<Dropdown.Item as={Link} to='/fermenter'>Stainless Steel Fermenter</Dropdown.Item>
<Dropdown.Item as={Link} to='/incubator'>Incubator</Dropdown.Item>
<Dropdown.Item as={Link} to='/magnetic-mixer'>Magnetic Mixer </Dropdown.Item>


<Dropdown item text='BIOEFFECTORS'>
  <Dropdown.Menu>
    <Dropdown.Item>Bio-Fertilizers</Dropdown.Item>
    <Dropdown.Item>Bio-Pesticides</Dropdown.Item>
    <Dropdown.Item>Aquaculture Mixtures</Dropdown.Item>
    <Dropdown.Item>Bio-Toilet Mixtures</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>
<Dropdown item text='SERVICES'>
  <Dropdown.Menu>
    <Dropdown.Item>Custom Solutions</Dropdown.Item>
    <Dropdown.Item>Training</Dropdown.Item>
    <Dropdown.Item>R&D</Dropdown.Item>
    <Dropdown.Item>Contract Manufacturing</Dropdown.Item>
  </Dropdown.Menu>
</Dropdown>

<Dropdown.Item>Blog</Dropdown.Item>
<Dropdown.Item>Careers</Dropdown.Item>
*/
