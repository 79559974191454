import React, { Component } from 'react';
import { Grid, Card } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import torocell from 'assets/rectbluemin.png';
import torobag from 'assets/torobagmin.png';
import rollerbottle from 'assets/rollerbottle-min.png';
import fermenter from 'assets/fermenter-min.png';
import laminarflow from 'assets/laminarflow-min.png';
import incubator from 'assets/lllogotrans.png';

import './theme.scss';

class BioProcessEquipment extends Component {
  render() {
    return (
      <div className="bio-process-equipment">
        <Grid>
          <Grid.Row centered className="products">
            <Grid.Column computer={12} mobile={16}>
              <div>
                <p className="heading">PRODUCTS</p>
              </div>
              <Card.Group itemsPerRow={3} stackable>
                <Card className="product-card" as={Link} to="/torocell-bioreactor">
                  <div
                    className="product-image"
                    style={{ backgroundImage: `url(${torocell})` }}
                  ></div>
                  <Card.Content>
                    <Card.Header>
                      Torocell<sup>&reg;</sup> Disposable Bioreactor
                    </Card.Header>
                  </Card.Content>
                </Card>

                <Card className="product-card" as={Link} to="/torocell-bag">
                  <div
                    className="product-image"
                    style={{ backgroundImage: `url(${torobag})` }}
                  ></div>
                  <Card.Content>
                    <Card.Header>
                      Torocell<sup>&reg;</sup> Disposable Bag
                    </Card.Header>
                  </Card.Content>
                </Card>

                <Card className="product-card" as={Link} to="/roller-bottle">
                  <div
                    className="product-image"
                    style={{ backgroundImage: `url(${rollerbottle})` }}
                  ></div>
                  <Card.Content>
                    <Card.Header>Roller Bottle</Card.Header>
                  </Card.Content>
                </Card>

                <Card className="product-card" as={Link} to="/fermenter">
                  <div
                    className="product-image"
                    style={{ backgroundImage: `url(${fermenter})` }}
                  ></div>
                  <Card.Content>
                    <Card.Header>Stainless Steel Fermenter</Card.Header>
                  </Card.Content>
                </Card>

                <Card className="product-card" as={Link} to="/laminar-flow">
                  <div
                    className="product-image"
                    style={{ backgroundImage: `url(${laminarflow})` }}
                  ></div>
                  <Card.Content>
                    <Card.Header>Laminar Flow</Card.Header>
                  </Card.Content>
                </Card>

                <Card className="product-card" as={Link} to="/incubator">
                  <div
                    className="product-image"
                    style={{ backgroundImage: `url(${incubator})`, backgroundSize: '20%' }}
                  ></div>
                  <Card.Content>
                    <Card.Header>Incubator</Card.Header>
                  </Card.Content>
                </Card>
              </Card.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default BioProcessEquipment;
