import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Sidebar, Segment } from 'semantic-ui-react';

import Navbar from 'components/Navbar';
import Footer from 'components/Footer';
import SideNav from 'components/SideNav';

import './theme.scss';

function PageWrapper({ children }) {
  const [visible, setVisible] = useState(true);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (visible) {
      setVisible(false);
    }
  }, [location, visible]);

  const toggleVisibility = e => {
    e.stopPropagation();
    setVisible(!visible);
  };

  const hideSidebar = () => {
    setVisible(false);
  };

  if (visible) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }

  return (
    <div className="page-with-sidebar">
      <Sidebar.Pushable as={Segment}>
        <SideNav visible={visible} />
        <Sidebar.Pusher onClick={hideSidebar}>
          <Segment basic>
            <Navbar toggleSidebar={toggleVisibility} />
            <Outlet />
            <Footer />
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  );
}

export default PageWrapper;
